// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import notifications from './notifications'

import chat from '@src/views/apps/chat/store'
import todo from '@src/views/apps/todo/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import reimbursementInvoice from '@src/views/apps/reimbursement/store'
import proposal from '@src/views/apps/proposal/store'
import team from '@src/views/apps/team/store'
import teamview from '@src/views/apps/team/view/store'
import task from '@src/views/apps/task/store'
import taskworkflow from '@src/views/apps/taskworkflow/store'
import salesorg from '@src/views/reports/salesreport/store/index.js'
import recurring_task from '@src/views/apps/recurring-task/store'
import recurringinvoice from '@src/views/apps/recurring-invoice/store'

import register from '@src/views/pages/authentication/register-multi-steps/store'
import client from '@src/views/apps/client/store'
import leads from '@src/views/apps/leads/store'
import digitalsignature from '@src/views/apps/digital-signature/store'
import password from '@src/views/apps/passwords/store/index.js'
import service from '@src/views/apps/service/store'
import category from '@src/views/apps/category/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'

import importExcel from '@src/views/components/import/store'

import invoiceaccount from '@src/views/pages/account-settings/Invoice/store/invoiceaccount'
import invoicepayment from '@src/views/apps/invoice/preview/InvoicePayment/store/index.js'
import exemptionreason from '@src/views/pages/account-settings/Invoice/store/exemptionreason'
import reimbursement from '@src/views/pages/account-settings/Invoice/store/reimbursement'
import designation from '@src/views/pages/account-settings/RolesAndDesignation/store/designation'
import department from '@src/views/pages/account-settings/RolesAndDesignation/store/department'
import role from '@src/views/pages/account-settings/RolesAndDesignation/store/roles'
import dashboard from '@src/views/dashboard/ecommerce/store/index.js'
import attendance from '@src/views/apps/attendance/store/index.js'
import leaves from '@src/views/apps/leave/store/index.js'
import holidays from '@src/views/pages/account-settings/Attendance/store/holidays.js'
import shiftDetails from '@src/views/pages/account-settings/Attendance/store/shift-details.js'
import leavesettings from '@src/views/pages/account-settings/Attendance/store/leavesettings.js'
import profile from '@src/views/apps/profile/store/index.js'
import notes from '@src/views/apps/notes/store/index.js'
import organization from '@src/views/pages/account-settings/store/index.js'
import templates from '@src/views/apps/service/store/templates.js'
import timesheet from '@src/views/apps/timesheet/store/index.js'
import taskconversation from '@src/views/apps/task/store/taskconversation.js'
import licenseList from '@src/views/reports/client-license/store.js'
import employee_reimbursement from '@src/views/apps/employee-reimbursement/store/index.js'

import taskreport from '@src/views/reports/task/store/index.js'
import compliance from '@src/views/apps/compliance/store/index.js'
import salesreport from '@src/views/reports/sales/store/index.js'
import organizationmain from '@src/views/apps/organization/store/index.js'
import gstapi from '@src/views/apps/gstfiling/store/index.js'
import feeds from '@src/views/apps/feeds/store/index.js'

import teamDashboard from '@src/views/dashboard/ecommerce/team/store/index.js'
import salesDashboard from '@src/views/dashboard/ecommerce/sales/store/index.js'
import taskdashboard from '@src/views/dashboard/ecommerce/task/store/index.js'

const rootReducer = {
  auth,
  chat,
  todo,
  email,
  users,
  navbar,
  notifications,
  layout,
  service,
  category,
  client,
  leads,
  register,
  invoice,
  proposal,
  digitalsignature,
  password,
  task,
  calendar,
  ecommerce,
  dataTables,
  attendance,
  leaves,
  invoiceaccount,
  invoicepayment,
  exemptionreason,
  reimbursement,
  designation,
  team,
  teamview,
  department,
  role,
  dashboard,
  holidays,
  shiftDetails,
  leavesettings,
  profile,
  notes,
  organization,
  templates,
  timesheet,
  taskconversation,
  licenseList,
  taskreport,
  compliance,
  salesreport,
  organizationmain,
  importExcel,
  recurring_task,
  reimbursementInvoice,
  recurringinvoice,
  salesorg,
  taskworkflow,
  employee_reimbursement,
  gstapi,
  feeds,
  teamDashboard,
  salesDashboard,
  taskdashboard
}

export default rootReducer
