// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import { orgUserId } from '@src/helper/sassHelper'
const userId = orgUserId()

export const getData = createAsyncThunk('appShiftDetails/getData', async params => {
  const response = await axios.post(`/shiftDetails/list`, params)
  return {
    params,
    data: response.data.shiftDetails.shiftDetails,
    total: response.data.shiftDetails.total
  }
})

export const getShiftDetail = createAsyncThunk('appShiftDetails/getShiftDetail', async params => {
  const response = await axios.post(`/shiftDetails/get`, params)
  return response.data.shiftDetail
})

export const addShiftDetails = createAsyncThunk('appShiftDetails/addShiftDetails', async (data, { dispatch, getState }) => {

  await axios.post(`/shiftDetails/create`, data)
  await dispatch(getData(getState().shiftDetails.params))
  return []

})

export const updateBusinessHours = createAsyncThunk('appShiftDetails/updateBusinessHours', async (data, { }) => {

  await axios.post(`/shiftDetails/updateBusinessHours`, data)
  return []

})

export const updateShiftDetails = createAsyncThunk('appShiftDetails/updateShiftDetails', async (data, { dispatch, getState }) => {
  await axios.post(`/shiftDetails/update`, data)
  await dispatch(getData(getState().shiftDetails.params))
  return []
})

export const deleteShiftDetails = createAsyncThunk('appShiftDetails/deleteShiftDetails', async (id, { dispatch, getState }) => {
  await axios.post('/shiftDetails/delete', { id, updatedBy: userId })
  await dispatch(getData(getState().shiftDetails.params))
  return id
})

export const appShiftDetailsSlice = createSlice({
  name: 'appShiftDetails',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedShift: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
      })
      .addCase(getShiftDetail.fulfilled, (state, action) => {
        state.selectedShift = action.payload
      })

  }
})

export default appShiftDetailsSlice.reducer
